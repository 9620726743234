import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { overlay } from "../styles/util"
import { css } from "@emotion/core"

const AnyoneCanPlayGuitarPage = ({ data }) => (
  <Layout>
    <SEO title="Anyone Can Play Guitar" />
    <Img css={overlay} alt="Anyone Can Play Guitar logo" fluid={data.file.childImageSharp.fluid} />
    <section css={styles.section}>
      <p>
        Before shifting my career to doing web development, I taught guitar lessons for a living for 7.5 years.
        During that time and a little here and there since then, I recorded a bunch of videos on music theory and
        how it pertains specifically to the guitar. Check out these videos and more over at Youtube.
      </p>
      <a css={styles.a} href="https://www.youtube.com/user/acpgapex"><i className="fab fa-youtube"></i></a>
    </section>
    <section css={styles.section}>
      <h3>Guitar Blog</h3>
      <p>Here's a playlist of guitar blog videos, which represent the latest on where I am in my playing and what I'm thinking about.</p>
      <iframe title="Guitar Blog playlist" width="100%" height="315" src="https://www.youtube.com/embed/videoseries?list=PLc8y2l-WxKfk_3wTWrnzUFaChtjVuKZUG" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
    </section>
    <section css={styles.section}>
      <h3>Popular Playlists</h3>
      <p>Here are a handful of playlists of videos I've made that have gotten a lot of positive feedback from viewers.</p>
      <h4>Music Theory Basics for Guitar</h4>
      <iframe title="Music Theory Basics for Guitar playlist" width="100%" height="315" src="https://www.youtube.com/embed/videoseries?list=PLc8y2l-WxKfn_LOVl4zEvQz4RiuMaFMs3" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
      <h4>Rhythm Basics for Guitar</h4>
      <iframe title="Rhythm Basics for Guitar playlist" width="100%" height="315" src="https://www.youtube.com/embed/videoseries?list=PLc8y2l-WxKfm16hK2hHfJwfEa_5-j5vcq" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
      <h4>How to Play Major Scales on the Guitar</h4>
      <iframe title="How to Play Major Scales on the Guitar playlist" width="100%" height="315" src="https://www.youtube.com/embed/videoseries?list=PLc8y2l-WxKflD-X3G8VuhIkRuCOYt2tut" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
      <h4>12 Bar Blues Basics for Guitar</h4>
      <iframe title="12 Bar Blues Basics for Guitar playlist" width="100%" height="315" src="https://www.youtube.com/embed/videoseries?list=PLc8y2l-WxKflRkeQSLSv84B2QuNpwZCZd" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
    </section>
  </Layout>
)

const styles = {
  section: css`
    font-family: 'Open Sans', sans-serif;
    padding: 16px;
  `,
  a: css`
    font-size: 3rem;
    margin-bottom: 1.45rem;
    display: block;
  `
}

export default AnyoneCanPlayGuitarPage

export const query = graphql`
  query {
    file(relativePath: { eq: "acpg.png" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
